<template>
  <v-carousel
    cycle
    height="700"
    delimiter-icon="circle"
    style="margin:0 auto;width:1244px;height:700px;"
  >
    <template v-slot:prev="{ on, attrs }">
      <v-btn
        fab
        depressed
        small
        outlined
        color="#3ad52a"
        v-bind="attrs"
        v-on="on"
      >
        <span class="material-icons">
          chevron_left
        </span>
      </v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn
        fab
        depressed
        small
        outlined
        color="#3ad52a"
        v-bind="attrs"
        v-on="on"
      >
        <span class="material-icons">
          chevron_right
        </span>
      </v-btn>
    </template>
    <router-link to="/works">
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
      ></v-carousel-item>
    </router-link>
  </v-carousel>
</template>

<script>
  export default {
    data () {
      return {
        items: [
          {
            src: 'https://micomprocedure.com/c_img/Dungeon_title.webp'
          },
          {
            src: 'https://micomprocedure.com/c_img/Maze_title.webp'
          },
          {
            src: 'https://micomprocedure.com/c_img/Shinrin_title.webp'
          }
        ]
      }
    }
  }
</script>

<style lang="scss">
v-carousel {
  carousel-controls-bg: rgba(0,0,0, .7);
  carousel-dot-bg: rgba(0,0,0, .7);
  carousel-dot-inactive-opacity: .5;
  carousel-dot-active-opacity: 1;
  carousel-dot-hover-opacity: .8;
}
</style>