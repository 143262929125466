<template>
  <div class="container">
    <div class="main">
      <header>
        <div class="header-box">
          <div class="title-box" @click="scrollTop">
            <img
              class="proc-icon"
              src="../assets/common/proc_ico.svg"
              width="40"
              alt="micomprocedure"
            />
            <h1 class="maintitle">Micomprocedure</h1>
            <h1 class="subtitle">
              東北大学ゲーム制作サークル<br />マイコンプロシージャ
            </h1>
          </div>
          <nav class="navi">
            <ul class="menu-box">
              <li class="menu-item" @click="scrollAbout">
                <input type="button" id="scroll-1" value="ABOUT" />
              </li>
              <li class="menu-item" @click="scrollWorks">
                <input type="button" id="scroll-2" value="WORKS" />
              </li>
              <li class="menu-item" @click="scrollJoin">
                <input type="button" id="scroll-5" value="JOIN US" />
              </li>
            </ul>
            <ul class="menu-box2">
              <li class="menu-item2" @click="scrollAbout">
                <input type="button" id="scroll-1" value="サークル情報" />
              </li>
              <li class="menu-item2" @click="scrollWorks">
                <input type="button" id="scroll-2" value="作品一覧" />
              </li>
              <li class="menu-item2" @click="scrollJoin">
                <input type="button" id="scroll-5" value="入部手続き" />
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <div class="car_space"></div>
      <div v-bind:class="{ active: pcView }"><Carousel /></div>
      <div v-bind:class="{ active: semiView }"><CarouselSemi /></div>
      <div v-bind:class="{ active: mobileView }"><CarouselMobile /></div>
      <div class="about">
        <div class="caption1" id="about">ABOUT</div>
        <div class="caption2">サークル情報</div>
        <div class="fadeIn" v-show="visibleAbout">
          <ul class="about-box">
            <li class="about-left">名称</li>
            <li class="about-right">
              東北大学文化部登録団体<br />東北大学マイコンプロシージャ
            </li>
          </ul>
          <ul class="about-box">
            <li class="about-left">設立</li>
            <li class="about-right">1979年4月1日</li>
          </ul>
          <ul class="about-box">
            <li class="about-left">部室</li>
            <li class="about-right">
              川内北キャンパス内 川内サークル部室棟4階 416号室
            </li>
          </ul>
          <ul class="about-box">
            <li class="about-left">メールアドレス</li>
            <li class="about-right">proc.tohoku[at]gmail.com</li>
          </ul>
          <ul class="about-box">
            <li class="about-left">X（旧 Twitter）</li>
            <li class="about-right">@micom_procedure</li>
          </ul>
          <ul class="about-box">
            <li class="about-left">活動内容</li>
            <li class="about-right">ゲームの制作・発表など</li>
          </ul>
          <ul class="about-box">
            <li class="about-left">部費</li>
            <li class="about-right">年3000円</li>
          </ul>
        </div>
        <div id="point-2" class="space"></div>
      </div>
      <div class="works">
        <div class="caption1" id="works">WORKS</div>
        <div class="caption2">作品一覧</div>
        <div class="fadeIn" v-show="visibleWorks">
          <div class="works-box">
            <div class="works-content" v-for="con in contents" :key="con.id">
              <img v-bind:src="con.img" />
              <div @click="onClickBtn(con)">
                <div class="works-mask">
                  <div class="works-caption">{{ con.title }}</div>
                </div>
              </div>
            </div>
            <v-dialog
              width="80%"
              max-width="1366"
              scrollable
              v-model="dialog"
              v-if="currentcontent"
            >
              <div class="dialog">
                <div class="dialog-box">
                  <img v-bind:src="currentcontent.img" />
                  <div class="dialog-text">
                    <h1>{{ currentcontent.title }}</h1>
                    <h2>{{ currentcontent.genre }}</h2>
                    <p>{{ currentcontent.description }}</p>
                  </div>
                </div>
                <div class="mainbuttonarea2">
                  <a v-bind:href="currentcontent.link"
                    ><span class="mainbuttonspan"><b>DOWNLOAD</b></span></a
                  >
                </div>
              </div>
            </v-dialog>
          </div>
        </div>
        <div class="mainbuttonarea">
          <router-link to="/works"
            ><span class="mainbuttonspan" @click="scrollTop2"
              ><b>and more...</b></span
            ></router-link
          >
        </div>
        <div id="point-3" class="end-space"></div>
      </div>
      <div class="info">
        <div class="caption1" id="join">JOIN US</div>
        <div class="caption2">入部手続き</div>
        <div class="space"></div>
        <div class="fadeIn" v-show="visibleJoin">
          <div class="info-caption">新入部員募集中！</div>
          <div class="info-text">
            マイコンプロシージャは新入部員を随時募集しています！<br />未経験者、2年生以上の入部も大歓迎です。<br />実際、多くの新入生がプロセに入ってから創作活動を始めています。<br />一緒に創作活動をしてみませんか？<br />お待ちしています！
          </div>
        </div>
        <div class="space"></div>
        <div class="fadeIn" v-show="visibleSchedule">
          <div class="info-caption">年間スケジュール予定</div>
          <ul class="join-box">
            <li class="join-left" style="background: #ff8080">4月</li>
            <li class="join-right">新歓活動<br />スプリングフェスティバル</li>
          </ul>
          <ul class="join-box">
            <li class="join-left" style="background: #ffd280">5月</li>
            <li class="join-right">新入生講習会</li>
          </ul>
          <ul class="join-box">
            <li class="join-left" style="background: #ffff80">6月</li>
            <li class="join-right">
              新入生役職希望調査<br />新入生プロジェクトチーム分け
            </li>
          </ul>
          <ul class="join-box">
            <li class="join-left" style="background: #80d280">7月</li>
            <li class="join-right">オープンキャンパス</li>
          </ul>
          <ul class="join-box">
            <li class="join-left" style="background: #80d2ff">8月</li>
            <li class="join-right">α版発表会</li>
          </ul>
          <ul class="join-box">
            <li class="join-left" style="background: #8080ff">10月</li>
            <li class="join-right">β版発表会</li>
          </ul>
          <ul class="join-box">
            <li class="join-left" style="background: #d280d2">11月</li>
            <li class="join-right">東北大学祭</li>
          </ul>
          <ul class="join-box">
            <li class="join-left" style="background: #aaa">不定期</li>
            <li class="join-right">
              プロセゲームジャム<br />DTM交流会<br />3D交流会
            </li>
          </ul>
        </div>
        <div class="space"></div>
        <div class="spaceAdjustment" v-show="!visibleFlow"></div>
        <div class="fadeIn" v-show="visibleFlow">
          <div class="info-caption">入部までの流れ</div>
          <div class="flowtitle">
            <span class="flowline">
              <img
                src="../assets/common/iconmonstr-arrow-36.svg"
                class="rarr"
                alt="・"
              />
              見学
            </span>
          </div>
          <div class="info-text">
            メールまたはXのDMから、見学希望の旨と希望する時間を書いてお伝えください。
          </div>
          <div class="info-text">
            <img
              src="../assets/common/iconmonstr-arrow-65.svg"
              class="darr"
              alt="↓"
            />
          </div>
          <div class="flowtitle">
            <span class="flowline">
              <img
                src="../assets/common/iconmonstr-arrow-36.svg"
                class="rarr"
                alt="・"
              />
              入部フォームに記入
            </span>
          </div>
          <div class="info-text">
            メールまたはXのDMから、入部希望の旨を書いてお伝えください。<br />入部フォームを共有しますので、記入・回答をお願いします。
          </div>
          <div class="info-text">
            <img
              src="../assets/common/iconmonstr-arrow-65.svg"
              class="darr"
              alt="↓"
            />
          </div>
          <div class="flowtitle">
            <span class="flowline">
              <img
                src="../assets/common/iconmonstr-arrow-36.svg"
                class="rarr"
                alt="・"
              />
              Discordに参加
            </span>
          </div>
          <div class="info-text">
            後日、Discordの招待がされますので、参加して部員名簿に氏名等を書くと入部完了です！
          </div>
        </div>
        <div class="space"></div>
      </div>
      <footer>
        <div class="snsicon">
          <ul class="snsiconlist">
            <li>
              <div class="footertext"><b>SHARE</b></div>
            </li>
          </ul>
          <ul class="snsiconlist">
            <li>
              <a
                href="https://twitter.com/share?url=https://micomprocedure.com/&text=Micomprocedure%28%E3%83%9E%E3%82%A4%E3%82%B3%E3%83%B3%E3%83%97%E3%83%AD%E3%82%B7%E3%83%BC%E3%82%B8%E3%83%A3%29+-+%E6%9D%B1%E5%8C%97%E5%A4%A7%E5%AD%A6%E3%82%B2%E3%83%BC%E3%83%A0%E5%88%B6%E4%BD%9C%E3%82%B5%E3%83%BC%E3%82%AF%E3%83%AB%0D%0A&related=micom_procedure"
                ><img
                  src="../assets/common/iconmonstr-twitter-4.svg"
                  alt="share on twitter"
              /></a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/sharer/sharer.php?u=https://micomprocedure.com/"
                ><img
                  src="../assets/common/iconmonstr-facebook-4.svg"
                  alt="share on facebook"
              /></a>
            </li>
            <li>
              <a
                href="http://line.me/R/msg/text/?Micomprocedure%28%E3%83%9E%E3%82%A4%E3%82%B3%E3%83%B3%E3%83%97%E3%83%AD%E3%82%B7%E3%83%BC%E3%82%B8%E3%83%A3%29+-+%E6%9D%B1%E5%8C%97%E5%A4%A7%E5%AD%A6%E3%82%B2%E3%83%BC%E3%83%A0%E5%88%B6%E4%BD%9C%E3%82%B5%E3%83%BC%E3%82%AF%E3%83%AB%0D%0Ahttps%3A%2F%2Fmicomprocedure.com%2F"
                ><img
                  src="../assets/common/iconmonstr-line-4.svg"
                  alt="share on line"
              /></a>
            </li>
          </ul>
        </div>
        <div class="copyright">
          &copy; Copyright micomprocedure 2021 all rights reserved.
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";
import CarouselSemi from "@/components/Carousel_semi.vue";
import CarouselMobile from "@/components/Carousel_mobile.vue";

export default {
  name: "Top",
  data() {
    return {
      dialog: false,
      contents: [
        {
          id: "241",
          img: require("../assets/works/breakingProcs_ico.webp"),
          title: "Procくずし",
          genre: "アクション",
          description:
            "異なる5種類のボールで遊べるブロックくずしゲーム。おなじみの普通のボールや重力をいじるボールなど、遊び方はいろいろ。",
          link: "https://micomprocedure.com/gamefiles/BreakingProcs_v0.1.1.zip",
        },
        {
          id: "242",
          img: require("../assets/works/sushitotte_ico.webp"),
          title: "ねぇ！寿司取って～",
          genre: "アクション",
          description: "お寿司を食べたいのに友達がうるさい!!!!!!!!!!!!!!!!!",
          link: "https://micomprocedure.com/gamefiles/ねぇ！寿司取って～.zip",
        },
      ],
      currentcontent: null,
      pcView: {},
      semiView: {},
      mobileView: {},
      visibleAbout: window.innerWidth < 800,
      visibleWorks: window.innerWidth < 800,
      visibleJoin: window.innerWidth < 800,
      visibleSchedule: window.innerWidth < 800,
      visibleFlow: window.innerWidth < 800,
    };
  },
  components: {
    Carousel,
    CarouselSemi,
    CarouselMobile,
  },
  methods: {
    scrollTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    scrollTop2: function () {
      window.scrollTo({
        top: 0,
      });
    },
    scrollAbout: function () {
      const element = document.getElementById("about");
      const position = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: position,
        behavior: "smooth",
      });
    },
    scrollWorks: function () {
      const element = document.getElementById("works");
      const position = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: position,
        behavior: "smooth",
      });
    },
    scrollJoin: function () {
      const element = document.getElementById("join");
      const position = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: position,
        behavior: "smooth",
      });
    },
    onClickBtn(con) {
      this.currentcontent = con;
      this.dialog = true;
    },
    handleResize: function () {
      if (window.innerWidth < 480) {
        this.pcView = true;
        this.semiView = true;
        this.mobileView = false;
      } else if (window.innerWidth < 800) {
        this.pcView = true;
        this.semiView = false;
        this.mobileView = true;
      } else {
        this.pcView = false;
        this.semiView = true;
        this.mobileView = true;
      }
    },
    handleFadeInAbout() {
      if (window.innerWidth < 800) {
        this.visibleAbout = true;
      }
      if (!this.visibleAbout) {
        this.visibleAbout = window.scrollY > 200;
      }
    },
    handleFadeInWorks() {
      if (window.innerWidth < 800) {
        this.visibleWorks = true;
      }
      if (!this.visibleWorks) {
        this.visibleWorks = window.scrollY > 900;
      }
    },
    handleFadeInJoin() {
      if (window.innerWidth < 800) {
        this.visibleJoin = true;
      }
      if (!this.visibleJoin) {
        this.visibleJoin = window.scrollY > 2300;
      }
    },
    handleFadeInSchedule() {
      if (window.innerWidth < 800) {
        this.visibleSchedule = true;
      }
      if (!this.visibleSchedule) {
        this.visibleSchedule = window.scrollY > 3000;
      }
    },
    handleFadeInFlow() {
      if (window.innerWidth < 800) {
        this.visibleFlow = true;
      }
      if (!this.visibleFlow) {
        this.visibleFlow = window.scrollY > 3600;
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("scroll", this.handleFadeInAbout);
    window.addEventListener("scroll", this.handleFadeInWorks);
    window.addEventListener("scroll", this.handleFadeInJoin);
    window.addEventListener("scroll", this.handleFadeInSchedule);
    window.addEventListener("scroll", this.handleFadeInFlow);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleFadeInAbout);
    window.removeEventListener("scroll", this.handleFadeInWorks);
    window.removeEventListener("scroll", this.handleFadeInJoin);
    window.removeEventListener("scroll", this.handleFadeInSchedule);
    window.removeEventListener("scroll", this.handleFadeInFlow);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "../assets/stylesheet.css";
</style>
<style>
.active {
  display: none;
}
</style>
