<template>
  <div class="container">
    <header>
      <div class="header-box">
        <router-link to="/">
          <div class="title-box">
            <img
              class="proc-icon"
              src="../assets/common/proc_ico.svg"
              width="40"
              alt="micomprocedure"
            />
            <h1 class="maintitle">Micomprocedure</h1>
            <h1 class="subtitle">
              東北大学ゲーム制作サークル<br />マイコンプロシージャ
            </h1>
          </div>
        </router-link>
        <nav class="navi">
          <ul class="menu-box">
            <li class="menu-item" @click="scrollFreshman">
              <input type="button" id="scroll-1" value="FRESHMAN" />
            </li>
            <li class="menu-item" @click="scrollSenior">
              <input type="button" id="scroll-2" value="SENIOR" />
            </li>
          </ul>
          <ul class="menu-box2">
            <li class="menu-item2" @click="scrollFreshman">
              <input type="button" id="scroll-1" value="新入生プロジェクト" />
            </li>
            <li class="menu-item2" @click="scrollSenior">
              <input type="button" id="scroll-2" value="その他" />
            </li>
          </ul>
        </nav>
      </div>
    </header>
    <div class="works">
      <div class="caption1">WORKS</div>
      <div class="caption2">作品一覧</div>
      <div id="point-1" class="semi-space"></div>
      <div class="info-caption">2023年度新入生プロジェクト</div>
      <div class="works-box">
        <div class="works-content" v-for="con in contents23x" :key="con.id">
          <img v-bind:src="con.img" />
          <div @click="onClickBtn(con)">
            <div class="works-mask">
              <div class="works-caption">{{ con.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="semi-space"></div>
      <div class="info-caption">2022年度新入生プロジェクト</div>
      <div class="works-box">
        <div class="works-content" v-for="con in contents22x" :key="con.id">
          <img v-bind:src="con.img" />
          <div @click="onClickBtn(con)">
            <div class="works-mask">
              <div class="works-caption">{{ con.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="semi-space"></div>
      <div class="info-caption">2021年度新入生プロジェクト</div>
      <div class="works-box">
        <div class="works-content" v-for="con in contents21x" :key="con.id">
          <img v-bind:src="con.img" />
          <div @click="onClickBtn(con)">
            <div class="works-mask">
              <div class="works-caption">{{ con.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="semi-space"></div>
      <div class="info-caption">2020年度新入生プロジェクト</div>
      <div class="works-box">
        <div class="works-content" v-for="con in contents20x" :key="con.id">
          <img v-bind:src="con.img" />
          <div @click="onClickBtn(con)">
            <div class="works-mask">
              <div class="works-caption">{{ con.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div id="point-2" class="semi-space"></div>
      <div class="space"></div>
      <div class="info-caption">2023年度東北大学祭出展作品</div>
      <div class="works-box">
        <div class="works-content" v-for="con in contents23y" :key="con.id">
          <img v-bind:src="con.img" />
          <div @click="onClickBtn(con)">
            <div class="works-mask">
              <div class="works-caption">{{ con.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <dev class="semispace"></dev>
      <div class="info-caption">2022年度東北大学祭出展作品</div>
      <div class="works-box">
        <div class="works-content" v-for="con in contents22y" :key="con.id">
          <img v-bind:src="con.img" />
          <div @click="onClickBtn(con)">
            <div class="works-mask">
              <div class="works-caption">{{ con.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div id="point-3" class="semi-space"></div>
      <div class="space"></div>
      <div class="info-caption">2024年度スプリングフェスティバル出展作品</div>
      <div class="works-box">
        <div class="works-content" v-for="con in contents24z" :key="con.id">
          <img v-bind:src="con.img" />
          <div @click="onClickBtn(con)">
            <div class="works-mask">
              <div class="works-caption">{{ con.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="space"></div>
      <v-dialog
        width="80%"
        max-width="1366"
        scrollable
        v-model="dialog"
        v-if="currentcontent"
      >
        <div class="dialog">
          <div class="dialog-box">
            <img v-bind:src="currentcontent.img" />
            <div class="dialog-text">
              <h1>{{ currentcontent.title }}</h1>
              <h2>{{ currentcontent.genre }}</h2>
              <p>{{ currentcontent.description }}</p>
            </div>
          </div>
          <div class="mainbuttonarea2">
            <a v-bind:href="currentcontent.link"
              ><span class="mainbuttonspan"><b>DOWNLOAD</b></span></a
            >
          </div>
        </div>
      </v-dialog>
    </div>
    <footer>
      <div class="snsicon">
        <ul class="snsiconlist">
          <li>
            <div class="footertext"><b>SHARE</b></div>
          </li>
        </ul>
        <ul class="snsiconlist">
          <li>
            <a
              href="https://twitter.com/share?url=https://micomprocedure.com/&text=Micomprocedure%28%E3%83%9E%E3%82%A4%E3%82%B3%E3%83%B3%E3%83%97%E3%83%AD%E3%82%B7%E3%83%BC%E3%82%B8%E3%83%A3%29+-+%E6%9D%B1%E5%8C%97%E5%A4%A7%E5%AD%A6%E3%82%B2%E3%83%BC%E3%83%A0%E5%88%B6%E4%BD%9C%E3%82%B5%E3%83%BC%E3%82%AF%E3%83%AB%0D%0A&related=micom_procedure"
              ><img src="../assets/common/iconmonstr-twitter-4.svg"
            /></a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/sharer/sharer.php?u=https://micomprocedure.com/"
              ><img src="../assets/common/iconmonstr-facebook-4.svg"
            /></a>
          </li>
          <li>
            <a
              href="http://line.me/R/msg/text/?Micomprocedure%28%E3%83%9E%E3%82%A4%E3%82%B3%E3%83%B3%E3%83%97%E3%83%AD%E3%82%B7%E3%83%BC%E3%82%B8%E3%83%A3%29+-+%E6%9D%B1%E5%8C%97%E5%A4%A7%E5%AD%A6%E3%82%B2%E3%83%BC%E3%83%A0%E5%88%B6%E4%BD%9C%E3%82%B5%E3%83%BC%E3%82%AF%E3%83%AB%0D%0Ahttps%3A%2F%2Fmicomprocedure.com%2F"
              ><img src="../assets/common/iconmonstr-line-4.svg"
            /></a>
          </li>
        </ul>
      </div>
      <div class="copyright">
        &copy; Copyright micomprocedure 2021 all rights reserved.
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      contents23x: [
        {
          id: "23a",
          img: require("../assets/works/Dungeon_ico.webp"),
          title: "ダンジョン自由研究",
          genre: "2DRPG",
          description:
            "天才少年のイタズラに巻き込まれた主人公とその友人たち。主人公たちはダンジョンに迷い込み、天才少年が仕掛けた罠や敵に苦しめられてしまう！友人たちと協力して天才少年が作ったダンジョンから脱出しよう！",
          link: "https://micomprocedure.com/gamefiles/dungeon_kenkyu-1.1.1.zip",
        },
        {
          id: "23b",
          img: require("../assets/works/MazeOfTheUnseen_ico.webp"),
          title: "Maze of the Unseen",
          genre: "3Dアクション",
          description:
            "君は【境界空間】~LiminalSpace~に飛ばされてしまった 迫りくる敵を避けて不可視の迷路から脱出せよ",
          link: "https://micomprocedure.com/gamefiles/Maze_of_the_Unseen_Ver1.0.5.zip",
        },
        {
          id: "23c",
          img: require("../assets/works/shinrinakumu_ico.webp"),
          title: "森林悪夢",
          genre: "3Dホラー",
          description:
            "林間学校の肝試し中、あなたは深い森に迷い込んでしまう。3本の鍵を見つけ、化け物をかわし、呪われた森を脱出せよ!",
          link: "https://micomprocedure.com/gamefiles/ShinrinAkumu.zip",
        },
      ],
      contents22x: [
        {
          id: "22a",
          img: require("../assets/works/Investigation_ico.webp"),
          title: "Investigation",
          genre: "3Dホラー",
          description:
            "とある探偵事務所に飛び込んだ、行方不明となった女性の捜索依頼。彼女を探し、探偵はホラースポットである古い洋館に足を踏み入れる。化け物から逃げつつ謎を解く、3Dホラーゲーム。",
          link: "https://micomprocedure.com/gamefiles/Investigation_TeamA.zip",
        },
        {
          id: "22b",
          img: require("../assets/works/Adventure_of_Dongbei_ico.webp"),
          title: "東北大学生の冒険",
          genre: "3Dアクション",
          description: "no description",
          link: "https://micomprocedure.com/gamefiles/2022_team_B.zip",
        },
        {
          id: "22c",
          img: require("../assets/works/Fantasy_Fantasia_ico.webp"),
          title:
            "一般大学生の俺が気付いたらファンタジー異世界に転移していたので、とりあえず街を目指すことにした。",
          genre: "2DRPG",
          description:
            "まだこの世界に来たばかりの3人。強くなるためにはお金を集めて強い装備を整えるしかない！！魔物と戦いお金を集めてボスを倒せ！！！",
          link: "https://micomprocedure.com/gamefiles/Fantasy_Fantasia-1.1.1.zip",
        },
      ],
      contents21x: [
        {
          id: "21a",
          img: require("../assets/works/THE_UFO_ico.webp"),
          title: "THE Unexpected Fighting Operation",
          genre: "2Dアクション",
          description:
            "に入れ、宇宙船の修理そして帰還を目指すスペース・ファンタジーゲームです。",
          link: "https://micomprocedure.com/gamefiles/THEUnexpectedFightingOperation.zip",
        },
        {
          id: "21b",
          img: require("../assets/works/harusagashi_ico.webp"),
          title: "はるさがし",
          genre: "2Dアクション",
          description:
            "歩行と飛行を切り替えながらキャラクターを操作し、さがしものを集めて春を取り戻せ！",
          link: "https://micomprocedure.com/gamefiles/Harusagashi_V1.2.zip",
        },
        {
          id: "21c",
          img: require("../assets/works/Escape_from_Shrine_ico.webp"),
          title: "神社からの脱出",
          genre: "3Dホラー",
          description:
            "ミニゲームをクリアしながら、神社からの脱出を目指すゲーム。",
          link: "https://micomprocedure.com/gamefiles/神社からの脱出.zip",
        },
        {
          id: "21d",
          img: require("../assets/works/Gravity-Lost_ico.webp"),
          title: "Gravity-Lost",
          genre: "3Dアクション",
          description:
            "ロボットのラヴィはとある事故をきっかけにほぼ全ての記憶をなくしてしまった。ラヴィは自身の記憶チップを回収しながら唯一覚えている白衣の男性を探す旅に出る・・・。",
          link: "https://micomprocedure.com/gamefiles/Gravity-Lost_v2.0.zip",
        },
        {
          id: "21e",
          img: require("../assets/works/R_T_A_ico.webp"),
          title: "落単・タイム・アタック！",
          genre: "2Dアクション",
          description:
            "―――限界大学生に刻一刻と迫る再履の音―――<br>あなたは落単間近の大学生（以下、イカトン）。レポートの提出を目指しキャンパスを駆け巡ります。道中の「金のレポート」を回収し、高評定を目指しましょう。",
          link: "https://micomprocedure.com/gamefiles/落単RTA.zip",
        },
        {
          id: "21f",
          img: require("../assets/works/Ns_ico.webp"),
          title: "コレクト・キューブ",
          genre: "3Dパズル",
          description: "No description",
          link: "https://micomprocedure.com/gamefiles/Collect_Cube.zip",
        },
      ],
      contents20x: [
        {
          id: "20a",
          img: require("../assets/works/crossfade_ico.webp"),
          title: "CrossFade",
          genre: "2D音楽",
          description: "音楽に合わせて、四方向から迫りくる敵を倒そう。",
          link: "https://micomprocedure.com/gamefiles/CrossFade.zip",
        },
        {
          id: "20b",
          img: require("../assets/works/tokinokadai_ico.webp"),
          title: "トキノカダイ",
          genre: "2Dアクション",
          description:
            "時を止める力を持つ少女は宿題が終わらない。タイミングよく時を止めて敵を倒し、ボスステージを攻略しよう。",
          link: "https://micomprocedure.com/gamefiles/TokinokadaiVer1.2.zip",
        },
        {
          id: "20c",
          img: require("../assets/works/tCg_ico.webp"),
          title: "TeamC no game",
          genre: "2Dパズル",
          description: "様々な能力をもつロボットと一緒に地形を略しよう。",
          link: "https://micomprocedure.com/gamefiles/TeamCNoGame.zip",
        },
        {
          id: "20d",
          img: require("../assets/works/shirokuma_ico.webp"),
          title: "城熊~shirokuma~",
          genre: "3Dアクション",
          description: "城に囚われた熊を脱出させよう！",
          link: "https://micomprocedure.com/gamefiles/shirokuma_1.11.zip",
        },
      ],
      contents23y: [
        {
          id: "231",
          img: require("../assets/works/BallDropGame3D_ico.webp"),
          title: "BallDropGame3D",
          genre: "3Dパズル",
          description:
            "ボールを好きな位置に落としていき、同じボールをくっつけて大きく育てて高得点を狙うゲームです。 3Dになっているので、2Dとはまた違った面白さを味わえます。",
          link: "https://micomprocedure.com/gamefiles/BallDropGame3D.zip",
        },
        {
          id: "232",
          img: require("../assets/works/dafa2sushiuti_icon.webp"),
          title: "寿司撃ち",
          genre: "2Dアクション",
          description:
            "寿司を発射して海鮮たちを倒そう！ 沢山の海鮮を倒せば倒すほど得点ＵＰ！",
          link: "https://micomprocedure.com/gamefiles/sushiuti1.2.0.zip",
        },
        {
          id: "233",
          img: require("../assets/works/NIGIRI_ico.webp"),
          title: "NIGIRI",
          genre: "放置ゲーム",
          description: "クリックで寿司を握ろう！寿司はおいしいので",
          link: "https://micomprocedure.com/gamefiles/NIGIRI_Ver1.0.zip",
        },
        {
          id: "234",
          img: require("../assets/works/RunDeliveryRoad_ico.webp"),
          title: "走れ!デリバリー道",
          genre: "2Dシミュレーション",
          description:
            "最近流行りのフードデリバリーをゲームに！お店から依頼を受けて制限時間内に配達しよう！あなたはいくら稼げるか！？",
          link: "https://micomprocedure.com/gamefiles/RunDeliveryRoad_v1.0.3.zip",
        },
        {
          id: "235",
          img: require("../assets/works/SlashAndBash_ico.webp"),
          title: "Slash and Bash",
          genre: "パズル",
          description:
            "正解の数字をより少ないターン数で的中させろ！ 初手3Slash0Bash（確率0.14%）の報告待ってます！",
          link: "https://micomprocedure.com/gamefiles/SlashAndBash.zip",
        },
        {
          id: "236",
          img: require("../assets/works/SquidGame_ico.webp"),
          title: "すイカゲーム",
          genre: "2Dパズル",
          description:
            "イカとイカをくっつけて酸っぱくしていくゲームです！ オンラインランキングへのランクインを目指せ！",
          link: "https://micomprocedure.com/gamefiles/すイカゲームv2.0.0beta.zip",
        },
      ],
      contents22y: [
        {
          id: "221",
          img: require("../assets/works/Ns_ico.webp"),
          title: "おやつカルパスかおやつカルパスじゃないかクイズ",
          genre: "クイズ",
          description:
            "タイトルの通り。部長と副部長が十数時間で作った謎の神ゲーム。",
          link: "https://micomprocedure.com/gamefiles/KarupasuQuiz_V1.0.zip",
        },
        {
          id: "222",
          img: require("../assets/works/Ns_ico.webp"),
          title: "灯-Lamp-",
          genre: "2Dパズル",
          description: "no description",
          link: "https://micomprocedure.com/gamefiles/-Lamp-.zip",
        },
        {
          id: "223",
          img: require("../assets/works/Ns_ico.webp"),
          title: "花弁回し",
          genre: "2Dパズル",
          description:
            "ベン図×ルービックキューブのパズルゲーム。花弁を回して色をそろえよう！何分で完成できる？",
          link: "https://micomprocedure.com/gamefiles/KaVennMawashi_V1.1.zip",
        },
      ],
      contents24z: [
        {
          id: "241",
          img: require("../assets/works/breakingProcs_ico.webp"),
          title: "Procくずし",
          genre: "アクション",
          description:
            "異なる5種類のボールで遊べるブロックくずしゲーム。おなじみの普通のボールや重力をいじるボールなど、遊び方はいろいろ。",
          link: "https://micomprocedure.com/gamefiles/BreakingProcs_v0.1.1.zip",
        },
        {
          id: "242",
          img: require("../assets/works/sushitotte_ico.webp"),
          title: "ねぇ！寿司取って～",
          genre: "アクション",
          description: "お寿司を食べたいのに友達がうるさい!!!!!!!!!!!!!!!!!",
          link: "https://micomprocedure.com/gamefiles/ねぇ！寿司取って～.zip",
        },
      ],
      currentcontent: null,
    };
  },
  methods: {
    scrollFreshman: function () {
      window.scrollTo({
        top: 300,
        behavior: "smooth",
      });
    },
    scrollSenior: function () {
      window.scrollTo({
        top: 2600,
        behavior: "smooth",
      });
    },
    onClickBtn(con) {
      this.currentcontent = con;
      this.dialog = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "../assets/stylesheet.css";
</style>
